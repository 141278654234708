import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ACTION_CONST } from "../../../constants";
import { helpers } from "../../../utils/helpers";
import { useIsConnected, useWalletAddress, useWeb3Utils } from "../../hook/useState";

const Header = () => {
  const dispatch = useDispatch();
  const isConnectWallet = useIsConnected();
  const walletAddress = useWalletAddress();
  const walletUtils = useWeb3Utils();
  const [amountBalance, setAmountBalance] = useState(0)

  useEffect(() => {
    if (walletUtils) {
      walletUtils.getBalanceAccount().then(balance => {
        setAmountBalance(balance)
      }).catch(err => console.log(err));

      const job = setInterval(() => {
        walletUtils.getBalanceAccount().then(balance => {
          setAmountBalance(balance)
        }).catch(err => console.log(err));
      }, 10 * 1000);
      dispatch({ type: ACTION_CONST.SET_JOB_GET_BALANCE, data: job })
    }
  }, [walletUtils])

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch({
      type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
    })
    window.location.reload();
  }
  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-md navbar-dark fixed-top pp-navbar">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center" href="https://PolyPad.com/" target="_blank">
            <img src="/images/logo.svg" width={200} alt="PolyPad" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto align-items-center">
              {
                !isConnectWallet ?
                  <li className="nav-item me-2">
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#" data-bs-toggle="modal" data-bs-target={"#connectWalletModal"} className="btn btn-primary">
                        <span>Connect Wallet</span>
                      </a>
                    </div>
                  </li>
                  :
                  <>
                    <li className="nav-item me-3">
                      <a href="#" data-bs-toggle="modal" data-bs-target={"#walletModal"} className="btn btn-primary">
                        <span>{helpers.formatTransactionHash(walletAddress, 4, 4)} - {amountBalance}</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#" onClick={(e) => handleLogout(e)} className="btn btn-primary">
                        <span>Disconnect</span>
                      </a>
                    </li>
                  </>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
