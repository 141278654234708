import React from 'react';

export default function ButtonStep({ divClass, btnText }) {
    return (
        <div className={`${divClass}`} >
            <button type="button" className="pp-step-trigger">
                <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse rx="10.0964" ry="10.1982" transform="matrix(0.861626 -0.507544 0.492493 0.870316 15.6027 14.0001)" fill="white" />
                    <path d="M28.6516 6.3137C30.5008 9.58162 26.1577 15.6722 18.951 19.9173C11.7443 24.1625 4.40296 24.9547 2.55371 21.6868" stroke="#061037" strokeWidth="1.70812" />
                    <ellipse rx="15.1446" ry="5.14238" transform="matrix(0.861626 -0.507544 0.492493 0.870316 15.5815 13.9623)" stroke="white" strokeWidth="1.70812" />
                </svg>
                <span className="pp-stepper-label">
                    {btnText}
                </span>
            </button>
        </div>
    );
}
