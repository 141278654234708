import React from 'react';

export default function AssetToken({ token, selected, onClickSelect }) {
    return (
        <button type='button' disabled={token.symbol === selected} className={`pp-item ${token.symbol === selected ? 'selected' : ''}`} onClick={() => onClickSelect(token)}>
            <div className="pp-item-icon">
                <img src={`${token.image}`} alt={token.symbol} />
            </div>
            <div className="pp-item-name">
                <span>{token.symbol}</span>
                <i className="ms-3 mdi mdi-check text-warning"></i>
            </div>
        </button>
    );
}
