import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_CONST, LIMIT_VALUE } from '../../constants';
import { helpers } from '../../utils/helpers';
import { BigNumber } from "bignumber.js";
export default function AmountInputPanel({ label, onAmountChange, network, token, isClearAmount, pauseSwap }) {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const balance = useSelector((state) => get(state, 'wallet.assetBalance', 0));
  const isConnectWallet = useSelector((state) => get(state, 'utils.isConnectWallet', false));
  const walletUtils = useSelector((state) => get(state, 'utils.walletUtils', null));
  const showBlockUI = useSelector((state) => get(state, "utils.blocking", false));

  useEffect(() => {
    if (walletUtils && token.contractAddress) {
      walletUtils.getTokenBalance({
        tokenAddress: token.contractAddress,
        network: network.id,
        decimal: token.decimals
      }).then(data => {
        setTokenBalance(data);
        dispatch({ type: ACTION_CONST.GET_ASSET_BALANCE_SUCCESS, data: data })
      })

      const jobGetAssetBalance = setInterval(() => {
        walletUtils.getTokenBalance({
          tokenAddress: token.contractAddress,
          network: network.id,
          decimal: token.decimals
        }).then(data => {
          setTokenBalance(data)
          dispatch({ type: ACTION_CONST.GET_ASSET_BALANCE_SUCCESS, data: data })
        })
      }, 10 * 1000)

      dispatch({
        type: ACTION_CONST.SET_JOB_GET_ASSET_BALANCE,
        data: jobGetAssetBalance
      });
    }
  }, [walletUtils, token, showBlockUI])


  const handleInputAmountChange = (e) => {
    if (isConnectWallet) {
      const value = e.target.value;
      if (Number(value) > tokenBalance) {
        handleMaxButtonClick();
        return;
      }
      if (helpers.validateAmountInput(value)) {
        const temBalance = helpers.formatNumberDownRoundWithExtractMax(Number(value), 4)
        setAmount(temBalance);
        onAmountChange(temBalance);
        return;
      }
      setAmount(value);
      onAmountChange(value);
    }
  };


  const handleMaxButtonClick = () => {
    if (walletUtils) {
      let { swapped, limit } = token;
      if (limit === 0) limit = LIMIT_VALUE.MAX;
      const tempBalance = helpers.formatNumberDownRoundWithExtractMax(Number(tokenBalance), 4)
      const max = limit - swapped;
      const bigMax = BigNumber(`${max}`);
      const bigBalance = BigNumber(`${tempBalance}`)
      if (bigBalance.lte(bigMax)) {
        setAmount(Number(tempBalance))
        onAmountChange(Number(tempBalance));
        return
      }
      if (bigBalance.gte(bigMax)) {
        setAmount(Number(max))
        onAmountChange(Number(max));
        return
      }
    }
  }


  useEffect(() => {
    if (isClearAmount) {
      setAmount(0)
    }
  }, [isClearAmount])

  return (
    <>
      <div className="pp-form-group mb-2">
        <div className="mb-1 d-flex align-items-center justify-content-between">
          <label className="form-label mb-0">{label}</label>
          <span className="pp-balance">Available: <b>{helpers.formatNumberDownRoundWithExtractMax(balance, 4)} {token.symbol}</b></span>
        </div>
        <div className="pp-input-group pp-input-amount">
          <input
            type="number"
            className="form-control"
            placeholder="0"
            value={amount}
            onChange={(e) => handleInputAmountChange(e)}
            disabled={!isConnectWallet}
          />
          {(!isConnectWallet || pauseSwap || token.pause) ? <></> : <span className="buttonx" style={{ cursor: 'pointer' }} onClick={handleMaxButtonClick}>MAX</span>}
        </div>
      </div>
      <div className="pp-form-note">
        <span>Daily Limit (Per Address)</span>
        <span className='text-white'><b>{`${token.addressSwapped || 0} / ${token.addressLimit === 0 ? `∞` : helpers.formatNumber(token.addressLimit) || 0}`}</b> {token.symbol}</span>
      </div>
      <div className="pp-form-note">
        <span>Daily Limit (Total)</span>
        <span className='text-white'><b>{`${token.swapped || 0} / ${token.limit === 0 ? `∞` : helpers.formatNumber(token.limit) || 0}`}</b> {token.symbol}</span>
      </div>
    </>
  );
}
