import React, { useMemo, useState } from 'react';
import AssetToken from '../AssetToken'
import { Search } from "../../component/DataTable";
import { Modal } from 'react-bootstrap';

const AssetSelectModal = ({ show, onClose, listTokens, onClickSelect, selected }) => {
    const [search, setSearch] = useState("");
    const listTokensData = useMemo(() => {
        let computedListTokens = listTokens;
        if (search) {
            computedListTokens = computedListTokens.filter(
                pool =>
                    pool.contractAddress.toLowerCase().includes(search.toLowerCase()) ||
                    pool.symbol.toLowerCase().includes(search.toLowerCase())
            );
        }
        return computedListTokens
    }, [listTokens, search]);

    return (
        <Modal dialogClassName='pp-modal' show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Search onSearch={value => setSearch(value)} placeholder="Search by token name..." />
                <div className="pp-token-results">
                    {listTokensData.length > 0 && <div className='pt-4'>
                        <div className="pp-token-result">Token name</div>
                        {
                            listTokensData.map((token, key) => {
                                return (
                                    <AssetToken token={token} key={key}
                                        selected={selected}
                                        onClickSelect={() => { onClickSelect(token); onClose() }}
                                    />
                                )
                            })
                        }
                    </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AssetSelectModal