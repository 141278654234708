import React from 'react';
import { useDispatch } from "react-redux";
import { ACTION_CONST } from "../../constants";
import useCopyToClipboard from './CopyToClibboard';
import { useWalletAddress } from '../hook/useState';

const WalletModal = () => {
    const dispatch = useDispatch();
    const [copied, setCopied] = useCopyToClipboard(1000);
    const walletAddress = useWalletAddress();
    const handleLogout = () => {
        dispatch({
            type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
        })
        window.location.reload();
    }

    return (
        <>
            <div className="modal fade pp-modal pp-modal-wallet" id="walletModal" tabIndex="-1" aria-labelledby="walletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="walletModalLabel">Your wallet</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 text-center">
                                <h5 className="pp-address-text">{walletAddress}</h5>
                                <button type='button' className="btn btn-sm btn-outline-primary" onClick={() => setCopied(walletAddress)}>
                                    <span>
                                        <span className="me-1">Copy Address</span>{!copied ? <i className="mdi mdi-content-copy"></i> : <i className="mdi mdi-check"></i>}
                                    </span>
                                </button>
                            </div>
                            <div className="text-center mt-3">
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleLogout}>
                                    <span>Disconnect</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WalletModal;


