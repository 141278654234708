import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

export default function NetworkInputPanel({
    label,
    networkList,
    selectedNetwork,
    onNetworkChange,
    connected
}) {
    const [currentNetwork, setCurrentNetwork] = useState(selectedNetwork);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setCurrentNetwork(selectedNetwork);

    }, [selectedNetwork]);

    const handleNetworkChange = (network) => {
        setCurrentNetwork(network);
        onNetworkChange(network);
    };


    return (
        <div className="pp-form-group mb-3">
            <label className="form-label mb-1">{label}</label>
            <div className="pp-input-network" onClick={() => setShow(true)} >
                {currentNetwork.badge && (
                    <span className={`badge pp-network-tag pp-network-${currentNetwork.badge}`}>
                        {currentNetwork.badge}
                    </span>
                )}
                {
                    (connected.id === currentNetwork.id) && <span className="pp-connected">Connected</span>
                }
                <img height="36" style={{ maxWidth: '36px', maxHeight: '36px' }} src={`/images/networks/${currentNetwork.id}-icon.svg`} />
                <div className="pp-network-name">{currentNetwork.name}</div>
                <i className="mdi mdi-chevron-right"></i>
            </div>

            <Modal dialogClassName='pp-modal' show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {networkList.map((value) => {
                        return (
                            <button key={value.id} type="button" disabled={value.name === currentNetwork.name} className={`pp-item ${value.name === currentNetwork.name ? 'selected' : ''}`} onClick={() => { handleNetworkChange(value); setShow(false) }}>
                                <div className="pp-item-icon">
                                    <img src={`/images/networks/${value.id}-icon.svg`} alt={value.name} />
                                </div>
                                <div className="pp-item-name">
                                    <span>{value.name}</span>
                                    <i className="ms-3 mdi mdi-check text-warning"></i>
                                </div>
                            </button>
                        )
                    })}
                </Modal.Body>
            </Modal>
        </div>
    );
}
