import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AmountInputPanel from '../../component/AmountInputPanel';
import AssetPanel from '../../component/AssetPanel';
import NetworkInputPanel from '../../component/NetworkInputPanel';
import Container from '../../component/Container';
import Row from '../../component/Row';
import { NETWORK_LIST, ACTION_CONST, LIMIT_VALUE } from '../../constants';
import StepModal from './StepModal';
import DestinationInput from '../../component/DestinationInput';
import { BigNumber } from "bignumber.js";
import { useIsConnected, useWeb3Utils } from '../../component/hook/useState';
import { toast } from 'react-toastify';
import { get } from 'lodash';

const defaultInputNetwork = NETWORK_LIST.find(e => e.defaultInput == true);
const defaultOutputNetwork = NETWORK_LIST.find(e => e.defaultOutPut == true);

const BridgePortalPage = (props) => {
    const dispatch = useDispatch();
    const walletUtils = useWeb3Utils()
    const isConnectWallet = useIsConnected();
    const [listTokens, setListTokens] = useState([])
    const [amount, setAmount] = useState(0);
    const [destinationAddress, setDestinationAddress] = useState("");
    const [inputNetwork, setInputNetwork] = useState(defaultInputNetwork);
    const [outputNetwork, setOutputNetwork] = useState(defaultOutputNetwork);
    const [tokenSelected, setTokenSelected] = useState({});
    const [isValidAddress, setIsValidAddress] = useState(false);
    const [enableSwapButton, setEnableSwapButton] = useState(false);
    const [currentNetWork, setCurrentNetWork] = useState("");
    const [isClearAmount, setIsClearAmount] = useState(false)
    const [pauseSwap, setPauSwap] = useState(true);
    const [showStepModal, setShowStepModal] = useState(false);

    const handleInputAmountChange = (value) => {
        setAmount(value);
    };

    useEffect(() => {
        if (walletUtils) {
            const network = walletUtils.getCurrentNetWork()
            setCurrentNetWork(NETWORK_LIST.find(e => e.id === network))
        }
    }, [inputNetwork, walletUtils])


    //check enable swap button
    useEffect(() => {
        if (walletUtils) {
            // const currentNetWork =  walletUtils.getCurrentNetWork()
            if (currentNetWork && tokenSelected) {
                if (Number(amount) > 0 && currentNetWork.id === inputNetwork.id) {
                    let { swapped, limit, networks } = tokenSelected;
                    //check address is admin token
                    if (limit === 0) limit = LIMIT_VALUE.MAX
                    const bigAmount = BigNumber(`${amount}`)
                    const bigMax = BigNumber(`${limit - swapped}`);
                    if (bigAmount.gte(0) && bigAmount.lte(bigMax)) {
                        setEnableSwapButton(true)
                    } else {
                        setEnableSwapButton(false)
                    }
                    return;
                } else {
                    setEnableSwapButton(false)
                }
                //if token selected pause disable 
            }
        } else {
            setEnableSwapButton(false)
        }
    }, [amount, currentNetWork, walletUtils, inputNetwork, tokenSelected])

    const handleNetworkSwap = () => {
        const network = inputNetwork;
        setInputNetwork(outputNetwork);
        setOutputNetwork(network);
    };

    const handleInputNetworkChange = (value) => {
        if (value === outputNetwork) {
            handleNetworkSwap(value);
        } else {
            setInputNetwork(value);
        }
    };

    const handleOutputNetworkChange = (value) => {
        if (value === inputNetwork) {
            handleNetworkSwap(value);
        } else {
            setOutputNetwork(value);
        }
    };


    //handle select Token bridge
    const handleSelectedToken = (value) => {
        setTokenSelected(value)
    }

    //
    const handleOnchangeDestination = useCallback((value) => {
        setDestinationAddress(value);
    }, [])

    const handleCheckValidToAddress = (value) => {
        // console.log(value);
        setIsValidAddress(value)
    }

    //
    useEffect(() => {
        dispatch({
            type: ACTION_CONST.CURRENT_INPUT_NETWORK,
            data: inputNetwork.id
        })
        if (currentNetWork && currentNetWork.id !== inputNetwork.id) {
            toast.warn(`Wrong network You need connect to ${NETWORK_LIST.find(e => e.id == currentNetWork.id).name}!`);
        }
    }, [inputNetwork]);

    //get list token
    useEffect(() => {
        if (walletUtils)
            //get info pair token from smart contract 
            walletUtils.getInfo().then(data => {
                if (data) {
                    const listTokensTemp = data.tokens.filter(a =>
                        (
                            outputNetwork.id.toLocaleUpperCase() === 'BSC' &&
                                (a.networks.toLocaleUpperCase() === 'BSC' || a.networks.toLocaleUpperCase() === 'BSC2')
                        ) ||
                        (
                            outputNetwork.id.toLocaleUpperCase() !== 'BSC' &&
                            a.networks.toLocaleUpperCase() === outputNetwork.id.toLocaleUpperCase()
                        )
                    );
                    // debugger
                    setListTokens(listTokensTemp);
                    setPauSwap(data.paused)
                }
            })
    }, [walletUtils, outputNetwork])

    //set default token
    useEffect(() => {
        if (listTokens.length > 0) {
            if (!tokenSelected.symbol)
                setTokenSelected(listTokens[0])
        }
    }, [listTokens])

    const handleClearAmount = useCallback(() => {
        setAmount(0);
        setIsClearAmount(true)
    }, [])

    const handleSwapped = (tokenAddress) => {
        // setIsSwappedSDone(tokenAddress)
        if (tokenAddress && walletUtils) {
            walletUtils.getInfo().then(data => {
                if (data) {
                    const listTokensTemp = data.tokens.filter(a =>
                        (
                            outputNetwork.id.toLocaleUpperCase() === 'BSC' &&
                                (a.networks.toLocaleUpperCase() === 'BSC' || a.networks.toLocaleUpperCase() === 'BSC2')
                        ) ||
                        (
                            outputNetwork.id.toLocaleUpperCase() !== 'BSC' &&
                            a.networks.toLocaleUpperCase() === outputNetwork.id.toLocaleUpperCase()
                        )
                    );
                    setListTokens(listTokensTemp);
                    setPauSwap(data.paused)
                    const defaultToken = data.tokens.find(
                        e => e.contractAddress === tokenAddress &&
                        e.networks === outputNetwork.id.toLocaleUpperCase()
                    );
                    if (defaultToken) {
                        setTokenSelected(defaultToken)
                    }
                }
            })
        }
    }
    return (
        <>
            <Container>
                <Row>
                    <div className="col-xl-7 d-none d-xl-block text-center">
                        {/* <img src="/images/ufo.png" className='img-fluid' style={{ opacity: 0.7, marginTop: -100 }} /> */}
                    </div>
                    <div className="col-xl-5 col-lg-7 col-md-10">
                        <div className="pp-card">
                            <div className="pp-card-body">
                                <div className="pp-card-inner">
                                    <AssetPanel tokenList={listTokens}
                                        onChangeToken={handleSelectedToken}
                                        tokenSelected={tokenSelected}
                                        tokens={listTokens}
                                    />

                                    {/* Input NetWork Panel */}
                                    <div className="pp-input-network-wrap">
                                        <NetworkInputPanel
                                            label="From"
                                            networkList={NETWORK_LIST}
                                            selectedNetwork={inputNetwork}
                                            onNetworkChange={handleInputNetworkChange}
                                            isConnectWallet={isConnectWallet}
                                            connected={currentNetWork}
                                        />
                                        <button disabled={isConnectWallet}
                                            type="button"
                                            className="pp-swap"
                                            onClick={handleNetworkSwap}
                                        >
                                            <i className="mdi mdi-swap-horizontal"></i>
                                        </button>
                                        <NetworkInputPanel
                                            label="To"
                                            networkList={NETWORK_LIST}
                                            selectedNetwork={outputNetwork}
                                            onNetworkChange={handleOutputNetworkChange}
                                            connected={currentNetWork}
                                        />
                                    </div>

                                    <AmountInputPanel
                                        label="Amount"
                                        onAmountChange={handleInputAmountChange}
                                        network={inputNetwork}
                                        token={tokenSelected}
                                        isClearAmount={isClearAmount}
                                        pauseSwap={pauseSwap}
                                    />

                                    {/* <AssetBalance token={tokenSelected} network={inputNetwork} /> */}

                                    <DestinationInput
                                        outputNetwork={outputNetwork}
                                        inputNetwork={inputNetwork}
                                        onDestinationChange={handleOnchangeDestination}
                                        isValid={handleCheckValidToAddress}
                                    />

                                    <div className="mt-4 pt-1">
                                        {
                                            !isConnectWallet ?
                                                <a href="#" data-bs-toggle="modal" data-bs-target={"#connectWalletModal"} className="btn btn-primary w-100">
                                                    <span>Connect Wallet</span>
                                                </a>
                                                :
                                                <button
                                                    type='button'
                                                    className="btn btn-primary w-100"
                                                    disabled={!enableSwapButton || !isValidAddress || pauseSwap || get(tokenSelected, "paused", true)}
                                                    onClick={() => setShowStepModal(true)}>
                                                    <span>Swap</span>
                                                </button>
                                        }
                                    </div>
                                    <div className="pp-errors">
                                        {(!isConnectWallet) && <div className="pp-error text-center text-warning mt-3"><i className="mdi mdi-alert-outline me-1"></i>Please connect your wallet to swap.</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
            <StepModal amount={amount}
                token={tokenSelected}
                destination={destinationAddress}
                inputNetwork={currentNetWork}
                outputNetwork={outputNetwork}
                clearAmount={handleClearAmount}
                handleSwapped={handleSwapped}
                show={showStepModal}
                onClose={() => setShowStepModal(false)}
            />
        </>
    );
};

export default BridgePortalPage;
