import React, { useEffect, useState } from 'react';
import WalletExtensionUtils from "../../utils/walletExtensionUtils";
import { extensionName } from "../../constants/values";
import {
    isMetamaskAvailable,
    isBinanceExtensionAvailable,
    isTrustWalletAvailable,
    isTronLinkAvailable,
    isWeb3NetWorkSupport
} from "../../utils/utils";
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_CONST, NETWORK_LIST } from '../../constants';
import { get } from 'lodash';
import { isMobile } from 'web3modal';
import { toast } from 'react-toastify';

const defaultInputNetwork = NETWORK_LIST.find(e => e.defaultInput == true);

const ConnectWalletModal = () => {
    const dispatch = useDispatch();
    const [hasMetamask, setHasMetamask] = useState(false);
    const [hasTrustWallet, setHasTrustWallet] = useState(false);
    const [hasBinanceWallet, setHasBinanceWallet] = useState(false);
    const currentNetWork = useSelector((state) => get(state, "wallet.currentInputNetwork", defaultInputNetwork.id));
    const [hasTronLink, setHasTronLink] = useState(false);
    const [modalConnectTRXTitle, setModalTrxConnectTitle] = useState("")
    const [modalConnectBSCTitle, setModalBSCConnectTitle] = useState("")

    useEffect(() => {
        setTimeout(() => {
            addWallet();
        }, 3000);
    }, [currentNetWork]);

    const addWallet = () => {
        console.log("currentNetWork==>", currentNetWork);
        if (currentNetWork === 'BSC') {
            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(isBinanceExtensionAvailable());

        } else if (currentNetWork === 'ETH' || currentNetWork === 'KCC' || currentNetWork === 'VELAS'  || currentNetWork === 'POLYGON') {
            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(false);
        } else {
            setHasMetamask(false);
            setHasTrustWallet(false);
            setHasBinanceWallet(false);
        }
        if (currentNetWork === 'TRON') {
            setHasTronLink(isTronLinkAvailable());
        } else {
            setHasTronLink(false)
        }
    };

    useEffect(() => {
        if (isMobile() && !hasTronLink) {
            setModalTrxConnectTitle("Please open site in Tronlink app")
        }
        else {
            setModalTrxConnectTitle("Connect to wallet")
        }

        if (isMobile() && !hasBinanceWallet && !hasMetamask && !hasTrustWallet) {
            setModalBSCConnectTitle("Please open site in Wallet app")
        }
        else {
            setModalBSCConnectTitle("Connect to wallet")
        }
    }, [isMobile, hasTronLink, hasBinanceWallet, hasBinanceWallet, hasTrustWallet])

    useEffect(() => {
        if (currentNetWork === 'BSC') {
            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(isBinanceExtensionAvailable());

        } else if (currentNetWork === 'ETH' || currentNetWork === 'KCC' || currentNetWork === 'VELAS'|| currentNetWork === 'POLYGON') {
            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(false);
        }
        else {
            setHasMetamask(false)
            setHasTrustWallet(false);
            setHasBinanceWallet(false);
        }
        if (currentNetWork === 'TRON') {
            setHasTronLink(isTronLinkAvailable());
        } else {
            setHasTronLink(false)
        }
    }, [currentNetWork])

    const connectWithExtension = async (extension) => {
        if (extension === extensionName.tronLink && currentNetWork == 'BSC') {
            toast.warn(`Wrong network! You need connect to ${NETWORK_LIST.find(e => e.id === currentNetWork).name}!`);
            return;
        }
        if ((extension === extensionName.metamask || extension === extensionName.binanceExtension || extension === extensionName.trustWallet) && currentNetWork == 'TRON') {
            toast.warn(`Wrong network! You need connect to ${NETWORK_LIST.find(e => e.id === currentNetWork).name}!`);
            return;
        }
        const temp = new WalletExtensionUtils(extension);
        //Connect action
        await temp.connect(currentNetWork);
        if (temp.checkWrongNetwork()) {
            toast.warn(`Wrong network! You need connect to ${NETWORK_LIST.find(e => e.id === currentNetWork).name}!`);
            return;
        }

        dispatch({
            type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
            data: temp
        })

        dispatch({
            type: ACTION_CONST.CURRENT_NET_WORK_EXTENSION,
            data: temp.getCurrentChainId()
        })

        await getBalanceAndAddress(temp);

        try {
            temp.accountsChanged(async (res) => {
                if (res !== undefined) {
                    dispatch({
                        type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                        data: temp
                    })
                    await getBalanceAndAddress(temp);
                }
            });
            return true;
        } catch (e) {
            dispatch({
                type: ACTION_CONST.REQUEST_DONE
            })
            console.log("error: ", e);
        }

        //if chain ID
        try {
            temp.chainChanged(async (chanId) => {
                await temp.connect(currentNetWork);
                if (temp.checkWrongNetwork()) {
                    toast.warn("Wrong network! You need connect to Binance smart chain network!");
                    return;
                }
                dispatch({
                    type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                    data: temp
                })

                dispatch({
                    type: ACTION_CONST.CURRENT_NET_WORK_EXTENSION,
                    data: temp.getCurrentChainId()
                })

                await getBalanceAndAddress(temp);
            })
        } catch (error) {

        }

    };
    const getExtension = () => {
        return extensionName;
    };

    const getBalanceAndAddress = async (extension) => {
        const walletAddress = await extension.getCurrentAddress();
        dispatch({
            type: ACTION_CONST.CONNECT_WALLET_SUCCESS,
            data: walletAddress
        })
    };


    return (
        <>
            <div className="modal fade pp-modal pp-modal-connect" id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        {
                            currentNetWork === 'TRON' &&
                            <>
                                <div className="modal-header">
                                    <h6 className="modal-title" id="connectWalletModalLabel">{modalConnectTRXTitle}</h6>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {
                                        hasTronLink &&
                                        <button data-bs-dismiss="modal" type="button" className="btn btn-primary w-100" data-bs-dismiss="modal" onClick={() =>
                                            connectWithExtension(
                                                getExtension().tronLink
                                            )
                                        }>
                                            <img className="btn-icon" src="/images/networks/tron-icon -white.svg" height="26" className="me-2" alt="TronLink" />
                                            <span>TronLink</span>
                                        </button>
                                    }

                                </div>

                            </>
                        }
                        {(isWeb3NetWorkSupport(currentNetWork)) &&
                            <>
                                <div className="modal-header">
                                    <h6 className="modal-title" id="connectWalletModalLabel">{modalConnectBSCTitle}</h6>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {
                                        hasMetamask &&
                                        <button data-bs-dismiss="modal" type="button" className="btn btn-primary w-100" data-bs-dismiss="modal" onClick={() =>
                                            connectWithExtension(
                                                getExtension().metamask
                                            )
                                        }>
                                            <img className="btn-icon" src="/images/metamask.svg" height="26" className="me-2" alt="Metamask" />
                                            <span>Metamask</span>
                                        </button>
                                    }
                                    {
                                        hasBinanceWallet &&
                                        <button data-bs-dismiss="modal" type="button" className="btn btn-primary w-100" data-bs-dismiss="modal" onClick={() =>
                                            connectWithExtension(
                                                getExtension().binanceExtension
                                            )
                                        }>
                                            <img className="btn-icon" src="/images/binance-extension.png" height="26" className="me-2" alt="Binance Chain Wallet" />
                                            <span>Binance Chain Wallet</span>
                                        </button>
                                    }
                                    {
                                        hasTrustWallet &&
                                        <button data-bs-dismiss="modal" type="button" className="btn btn-primary w-100" data-bs-dismiss="modal" onClick={() =>
                                            connectWithExtension(
                                                getExtension().trustWallet
                                            )
                                        }>
                                            <img className="btn-icon" src="/images/trust_platform.png" height="26" className="me-2" alt="Trust Wallet" />
                                            <span>Trust Wallet</span>
                                        </button>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConnectWalletModal;


