export const NETWORK_LIST = [
    // {
    //     id: 'ETH',
    //     name: 'Ethereum Network',
    //     badge: 'ERC20',
    //     currency: 'ETH',
    //     type: 'web3',
    //     defaultInput: false,
    //     defaultOutPut: false,
    // },
    {
        id: 'BSC',
        name: 'Binance Smart Chain Network',
        badge: '',
        currency: 'BNB',
        type: 'web3',
        defaultInput: true,
        defaultOutPut: false,
        
    },
    // {
    //     id: 'VELAS',
    //     name: 'Velas Network',
    //     badge: 'VRC20',
    //     currency: 'VLX',
    //     type: 'web3',
    //     defaultInput: false,
    //     defaultOutPut: true,
    // },
        {
        id: 'POLYGON',
        name: 'Polygon Network',
        badge: '',
        currency: 'MATIC',
        type: 'web3',
        defaultOutPut: true,
        defaultInput: false,
    },
    // {
    //     id: 'TRON',
    //     name: 'Tron Network',
    //     badge: 'TRC20',
    //     currency: 'TRX',
    //     type: 'tron',
    //     defaultOutPut: false,
    //     defaultInput: false,
      
    // },
    // {
    //     id: 'KCC',
    //     name: 'Kucoin Community Chain',
    //     badge: 'KRC20',
    //     currency: 'KSC',
    //     type: 'web3',
    //     defaultOutPut: true,
    //     defaultInput: false,
    // }
];

export const CHAIN_IDS = {
    eth: [1, 3],
    bsc: [56, 97],
    ksc: [321,322],
    vlx: [106, 111]
};
