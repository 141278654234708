import React from "react";

const CookiePolicyPage = (props) => {
  return (
    <>
      <div className="container pb-4 pt-5 policy-page">
        <div className="mb-5 text-center">
          
        </div>
        <div style={{ lineHeight: 1.7 }}>
          
        </div>
      </div>
    </>
  );
};

export default CookiePolicyPage;
