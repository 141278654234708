import React from "react";
import { Router, Switch } from "react-router-dom";
import { ROUTES } from "./constants";
import { PrivateRoute } from "./component/PrivateRoute";
import { history } from "./utils/history";
import BridgePortalPage from "./pages/BridgePortalPage/BridgePortal";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import CookiePolicyPage from "./pages/CookiePolicy";
import TermsOfUsePage from "./pages/TermsOfUse";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <PrivateRoute
            exact
            path={ROUTES.HOMEPAGE}
            component={BridgePortalPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.BRIDGE}
            component={BridgePortalPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.PRIVACY_POLICY}
            component={PrivacyPolicyPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.COOKIES_POLICY}
            component={CookiePolicyPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.TERMS_OF_USE}
            component={TermsOfUsePage}
          />
        </Switch>
      </Router>
      <ToastContainer />
    </>
  );
};
export default App;