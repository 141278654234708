import React from "react";
import { ROUTES } from "../../constants";
import { HashLink as Link } from 'react-router-hash-link';

const PrivacyPolicyPage = (props) => {
  return (
    <>
      <div className="container pb-4 pt-5 policy-page">
        <div className="text-center mb-5">
          
        </div>
        <div style={{ lineHeight: 1.7 }}>
          
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
