import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import chainHost from "../../contracts/chainHost.json";
import Web3 from "web3";
const TronWeb = require('tronweb')

const tronWeb = new TronWeb({
    fullHost: chainHost.tronMainnet
}
)

export default function DestinationInput({ inputNetwork, outputNetwork, onDestinationChange, isValid }) {

    const isConnectWallet = useSelector((state) => get(state, 'utils.isConnectWallet', false));
    const walletAddress = useSelector((state) => get(state, "utils.walletAddress", false));
    const walletUtils = useSelector((state) => get(state, 'utils.walletUtils', null));
    const [defaultValue, setDefaultValue] = useState("")
    const [readOnly, setReadOnly] = useState(false);
    const [isValidateAddress, setIsValidateAddress] = useState(false);
    const [isContractAddress, setIsContractAddress] = useState(false);


    useEffect(() => {

        // const bridgeNetwork = `${inputNetwork.id}_NETWORK`.toLocaleUpperCase();
        if (inputNetwork.type == outputNetwork.type) {
            // setReadOnly(true);
            setDefaultValue(walletAddress);
            onDestinationChange(walletAddress);
        } else {
            setDefaultValue("");
            setReadOnly(false);
        }

    }, [inputNetwork, onDestinationChange, outputNetwork, walletAddress]);

    const isContract = useCallback(async (network) => {
        const result = await walletUtils.isContract(defaultValue, network);
        setIsContractAddress(result)
        isValid(!result);
        console.log(`isContract: ${result}`)
    }, [defaultValue, walletUtils, isValid])

    useEffect(() => {
        let check = false;
        if (outputNetwork.type === "web3") {
            check = Web3.utils.isAddress(defaultValue)
            setIsValidateAddress(check)
        }
        if (outputNetwork.type === 'tron') {
            check = tronWeb.isAddress(defaultValue)
            setIsValidateAddress(check)
        }
        if (!isValidateAddress && check) {
            isContract(outputNetwork.id)
        }
        if (!isValidateAddress) {
            isValid(check)
        }

    }, [defaultValue, isContract, isValid, isValidateAddress, outputNetwork.id, outputNetwork.type])

    return (
        <>
            {
                isConnectWallet && <div className='pp-form-group pt-3'>
                    <label className="form-label mb-1">Destination</label>
                    <div className={`pp-input-group mb-3 pp-input-asset ${(!isValidateAddress && defaultValue) ? 'pp-control-invalid' : ''}`}>
                        <div className='pp-input-asset-value w-100'>
                            <img className='d-md-block d-none' src={`/images/networks/${outputNetwork.id}-icon.svg`} width={20} />
                            <input
                                onChange={(e) => {
                                    setIsValidateAddress(false)
                                    setIsContractAddress(false)
                                    setDefaultValue(e.target.value)
                                    onDestinationChange(e.target.value)
                                }
                                }
                                style={{
                                    fontFamily: 'Gilroy, sans-serif',
                                    fontWeight: 500,
                                    textTransform:'none',
                                    paddingRight:0
                                }}
                                type="text"
                                className="form-control"
                                placeholder={`Input your destination address`}
                                readOnly={readOnly}
                                value={defaultValue || ""}
                            />
                        </div>
                    </div>
                    {(!isValidateAddress && defaultValue) &&
                        <div className="pp-invalid-address"><i className="mdi mdi-alert me-1"></i>Invalid address</div>
                    }
                    {(isValidateAddress && defaultValue && isContractAddress) &&
                        <div className="pp-invalid-address"><i className="mdi mdi-alert me-1"></i>Cannot bridge to a contract address</div>
                    }
                    <div className="mb-3">
                        <div className="text-warning">
                            <i className="mdi mdi-information-outline me-1"></i>
                            This is the destination address on the {outputNetwork.name}.
                        </div>
                    </div>
                </div>
            }


        </>
    );
}
