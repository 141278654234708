import React from 'react';

export default function AssetSelected({ token, onSelect }) {
    return (
        <div className="pp-form-group mb-3">
            <label className="form-label mb-1">Asset</label>
            <div className="pp-input-group pp-input-asset" onClick={onSelect}>
                <div className="pp-input-asset-value">
                    {
                        (token && token.symbol) ? <>
                            <img height="18" src={token.image} alt={token.symbol} />
                            <span>{token.symbol}</span>
                        </> : <>
                            <img className='pp-placeholder' height="18" src="/images/logo.png" alt='' />
                            <span className='pp-placeholder'>Select asset</span>
                        </>
                    }
                </div>
                <i className="mdi mdi-chevron-right"></i>
            </div>
        </div>
    );
}
