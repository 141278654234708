import { get } from "lodash";
import { useSelector } from "react-redux";


export const useWeb3Utils = () => {
    return useSelector((state) =>
        get(state, 'utils.walletUtils', null)
    );
}


export const useShowBlockUI = () => {
    return useSelector((state) =>
        get(state, "utils.blocking", false)
    );
}

export const useIsConnected = () =>{
    return useSelector((state) => get(state, 'utils.isConnectWallet', false));
}

export const useWalletAddress = () =>{
    return useSelector((state) => get(state, "utils.walletAddress", false));
}

export const useShowModalHelp = ()=>{
    return useSelector((state) => get(state, "utils.showModalHelp", false));
}